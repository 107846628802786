<template>
  <b-modal
      id="modal-personalize"
      :title="$t('customize.customization')"
      hide-footer
      modal-class="modal-secondary"
      size="lg"
      scrollable
      v-if="selected_data.ids"
      v-model="showModal"
      @hide="$emit('personalize_modal_active')"
    >
      <b-card bg-variant="warning" text-variant="white">
        <b-card-title class="text-white">
         {{$t('customize.customize')}} {{isGroup ? selected_data.ids.length >1 ? $t('Groups'):$t('Group') : selected_data.ids.length >1 ? $t('Dreamers') :$t('models.dreamer')}}
        </b-card-title>
        <b-card-text>
          {{sliced_names}}
          {{selected_data.ids.length >5 ? '+'+(selected_data.ids.length - 5) :''}}
        </b-card-text>
      </b-card>
      <customization-component
        :dreamers="selected_data.ids"
        :isGroup="isGroup"
        @close-modal="closeModalHandler" 
        class="mt-2 pt-75"
      />
    </b-modal>
</template>

<script>
import CustomizationComponent from '@/views/sl-components/CustomizationComponent.vue'
import { BModal, VBModal, BCard, BCardText, BCardTitle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components:{
    BModal,
    BCard,
    BCardText, BCardTitle,
    CustomizationComponent
  },
  data(){
    return {
      showModal: false,
    };
  },
  props:{
    selected_data: Object,
    isGroup: Boolean
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  methods: {
    closeModalHandler() {
      this.showModal = false;
    },
  },
  computed:{
    sliced_names(){
      let names = this.selected_data.info.map(x=> x.name)
      return names.slice(0,5).join(', ')
    }
  }
}
</script>

<style>

</style>