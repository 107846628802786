<template>
  <b-modal id="modal-assign-pass" 
    ref="modal-assign-pass"
    :title="$t('children_passwords.edit_children_passwords')"
    modal-class="modal-secondary"
    size="lg"
    v-if="selected_data.ids"
    centered
    ok-only
    @hide="$emit('password_modal_active')"
    :ok-title="$t('forms.close')"
    ok-variant="outline-secondary"
    >
    <children-passwords-components 
      :info="selected_data.info"
      :dreamers="selected_data.ids"
      :isGroup="isGroup"
      class="mt-2 pt-75"
    />
  </b-modal>
</template>

<script>
import ChildrenPasswordsComponents from "@/views/sl-components/ChildrenPasswordsComponents.vue";
import {
  BCard, BCardText, BTable,
  BFormDatepicker,
  BButton, BRow, BCol, 
  BFormCheckbox,
  BFormGroup, BFormInput, BForm, BFormRadio,
  BAvatar, BAvatarGroup, VBTooltip,
  BCardGroup, BCardTitle, BCardFooter, BCardBody,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
      ChildrenPasswordsComponents,
        BCard, BCardText, 
        BFormDatepicker,
        BTable,
        BButton, BRow, BCol, 
        BFormCheckbox,
        BFormGroup, BFormInput, BForm, BFormRadio,
        BAvatar, BAvatarGroup,VBTooltip,
        BCardGroup, BCardTitle, BCardFooter, BCardBody,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    props:{
        selected_data: Object,
        isGroup: Boolean
    },
}
</script>

<style lang="scss" scoped>

</style>