<template>
  <b-modal
      id="modal-content-restriction"
      :title="$t('customize.mandatory')"
      hide-footer
      modal-class="modal-secondary"
      size="lg"
      scrollable
      v-if="selected_data.ids"
      @hide="$emit('content_restriction_modal_active')"
    >
      <b-card bg-variant="warning" text-variant="white">
        <b-card-title class="text-white">
         {{$t('customize.to_restrict_content')}} {{isGroup ? selected_data.ids.length >1 ? $t('Groups'):$t('Group') : selected_data.ids.length >1 ? $t('Dreamers') :$t('models.dreamer')}}
        </b-card-title>
        <b-card-text>
          {{sliced_names}}
          {{selected_data.ids.length >5 ? '+'+(selected_data.ids.length - 5) :''}}
        </b-card-text>
      </b-card>
      <content-restriction-component
        :info="selected_data.info"
        :dreamers="selected_data.ids"
        :isGroup="isGroup"
        class="mt-2 pt-75"
      />
    </b-modal>
</template>

<script>
import ContentRestrictionComponent from '@/views/sl-components/ContentRestrictionComponent.vue'
import { BModal, VBModal, BCard, BCardText, BCardTitle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components:{
    BModal,
    BCard,
    BCardText, BCardTitle,
    ContentRestrictionComponent
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props:{
    selected_data: Object,
    isGroup: Boolean
  },
  computed:{
    sliced_names(){
      let names = this.selected_data.info.map(x=> x.name)
      return names.slice(0,5).join(', ')
    }
  }
}
</script>

<style>

</style>