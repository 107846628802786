<template>
  <div>
    <!-- BREADCRUMBS -->
    <custom-breadcrumb
      :pageTitle="$t('Groups')"
      :breadcrumb="breadcrumb"
    ></custom-breadcrumb>
    
    <!-- ADD GROUP -->
    <add-new
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :school_id="school_id"
      @refetch-data="getData"
    />

    <modal-personalize
      :isGroup="true"
      :selected_data="selGroupsData"
    />
    <modal-content-restriction
      :isGroup="true"
      :selected_data="selGroupsData"
    />
    <modal-children-passwords
      :isGroup="true"
      :selected_data="selGroupsData"
      @password_modal_active="password_modal_active=false"
    />

    <!-- IMPORT WIZARD  -->
    <import-wizard
      v-if="showImportModal"
      @close="showImportModal = false"
      :school_id="$route.params.id"
    ></import-wizard>
    
    <b-card no-body v-else>
      <!-- FILTROS -->
      <filters
        ref="filter"
        context="groups"
        :school_id="school_id"
        @set-data="setData"
        @set-loading-data="setloadingData"
      /> 

      <!-- TABLA -->
      <paginated-table
        :data="groups"
        ability="SchoolGroups"
        link='school-groups'
        @manage-selected-rows="manageSelectedRows"
        @delete="deleteModel"
        :canRecover="false"
        :canDownload="false"
        :loading_data="loading_data"     
        :school_id="school_id"


        :buttonsOptions="!showImportModal ? buttonsOptions: importOptions"
        @close="showImportModal = false"
        @create="isAddNewSidebarActive = true"
        @import="showImportModal = true"
        @deleteSelection="deleteSelection"
        @customizeDreamers="$bvModal.show('modal-personalize')"
        @contentRestriction="$bvModal.show('modal-content-restriction')"
        @assignPasswords="$bvModal.show('modal-assign-pass')"
      ></paginated-table>
    </b-card>
  </div>
</template>

<script>
import { getSchoolGroups, deleteSelectedSchoolGroup, deleteSchoolGroup } from '@/api/routes'
import {
  BCard, BRow, BCol
} from 'bootstrap-vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import PaginatedTable from '@/views/sl-components/PaginatedTable.vue'
import Filters from '@/views/sl-components/Filters.vue'
import AddNew from './AddNew.vue'
import ImportWizard from '@/views/sl-components/ImportExcelWizard.vue'

import ModalPersonalize from '@/views/modals/ModalPersonalize.vue'
import ModalContentRestriction from '@/views/modals/ModalContentRestriction.vue'
import ModalChildrenPasswords from '@/views/modals/ModalChildrenPasswords.vue'


export default {
  components:{
    BCard, BRow, BCol,

    CustomBreadcrumb,
    PaginatedTable,
    ImportWizard,
    Filters,
    AddNew,

    ModalPersonalize,
    ModalContentRestriction,
    ModalChildrenPasswords,
  },
  data(){
    return {
      showImportModal: false,
      importOptions: [
        {
          action: "close",
          icon: "XIcon",
          name: this.$t('import_wizard.go_back_to_list')
        },
      ],
      isAddNewSidebarActive: false,
      selected: [],
      groups: [],
      loading_data: false,

      school_id: '',
      school:{},

      group:null,
      password_modal_active: false,
    }
  },
  mounted(){
    this.getData()
  },
  computed: {
    selGroupsData(){
      // console.log("selGroupsData", this.selected)
      // console.log("groups", this.groups)
      if(this.password_modal_active && this.group){
        let data = {
          ids: [this.group.id],
          info: [{
            id: this.group.id,
            name: this.group.name,
            avatar: this.group.id_avatar || 'null',
          }]
        }
        return data
      } else {
        let ids = this.selected.map(x => x.id)
        
        let g = ids.map(x => this.groups.filter(e=> e.id == x))
        let clean_g = g.map(x=>{ return{'id': x[0].id, 'name': x[0].name, 'avatar': x[0].id==0?'null': x[0].id_avatar, 'owner': x[0].owner}})


        let data = {
          ids: ids,
          info: clean_g
        }
        return data
      }
    },
    breadcrumb(){
          if(this.$store.getters['user/isSchool']){
            return [
              {
                text: this.$t('Schools'),
              },
              {
                text: this.school.name
              },
              {
                text: this.$t('Groups'),
                active: true,
              },
            ]
          } else {
            return [
              {
                text: this.$t('Schools'),
                to: 'schools'
              },
              {
                to: 'school-view',
                params: {
                  id: this.$route.params.id,
                },
                text: this.school.name
              },
              {
                text: this.$t('Groups'),
                active: true,
              },
            ]
          }
    },
    buttonsOptions(){
        let opt = [
          {
            action: "create",
            icon: "PlusIcon",
            variant: "success",
            name: this.$t('forms.add')
          },
          /* {
            action: "import",
            icon: "UploadIcon",
            name: "Import Group"
          }, */
        ]
        if(this.selected.length > 0){
          opt.push(
            {
              action: "customizeDreamers",
              icon: "SlidersIcon",
              name: this.$t('customize.customize'),
            }, 
            {
              action: "contentRestriction",
              icon: "ClockIcon",
              name: this.$t('customize.mandatory'),
            }, 
            {
              action: "assignPasswords",
              icon: "UnlockIcon",
              name: this.$t('children_passwords.edit_children_passwords'),
            }, 
            {
                action: "deleteSelection",
                icon: "Trash2Icon",
                name: this.$t('table.delete_selection'),
                variant: "danger",
              })
        }
        return opt
    },
  },
  methods: {
    showPasswordsModal(group){
      this.password_modal_active = true
      this.group = group

      this.$bvModal.show('modal-assign-pass')
    },
    manageSelectedRows(selected_rows){
      // console.log(selected_rows, "selected_rows")
      this.selected = selected_rows
    },
    async getData(){
        // console.log("loading", this.$store.getters['user/getRole'])
        let data = {
          school_id : this.$route.params.id ?? null
        }
        if(this.$store.getters['user/getRole'] == 'school-admin'){
          data.school_id = this.$store.getters['user/isSchool'].id
        }
        await this.$http.post(getSchoolGroups,data).then( response =>{
          // console.log(response)
          this.groups = response.data.groups;
          this.school = response.data.school
          //this.setData(response);
          if(this.$store.getters['user/isSchool']){
            this.school_id = this.$store.getters['user/isSchool'].id
          } else {
            this.school_id = this.$route.params.id;
          }

        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
    },
    deleteSelection(){
        let groups_data = this.selected
        let groups_array = groups_data.filter(x=> x.owner || x.id!=0 ).map(d=>d.id)
        // console.log(groups_array)
        if(groups_array.length == 0){
          this.$bvModal
          .msgBoxOk(this.$t('table.groups_cannot_be_deleted'), {
            title: this.$t('Warning'),
            size: 'sm',
            variant: 'warning',
            okTitle: this.$t('Understood'),
            hideHeaderClose: false,
            centered: true,
          })
        } else {
          const h = this.$createElement
          const messageVNode = h('div', {}, [
          h('p',{}, [
            this.$t('table.delete_groups_selection_confirmation', {num: groups_array.length}),
            ' ',
            h('strong', this.$t('table.groups_cannot_be_deleted')),
          ]),
          h('p', {}, this.$t('table.delete_groups_selection_dreamers_will_be_moved')),
          h('p', this.$t('table.delete_groups_selection_recover')),
        ])
          this.$bvModal
            .msgBoxConfirm([messageVNode], {
              title: this.$t('table.delete_selection'),
              size: 'sm',
              okVariant: 'danger',
              okTitle: this.$t('table.delete_selection'),
              cancelTitle: this.$t('forms.cancel'),
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if(value){
                let data = {
                  'groups':   groups_array
                }
                // console.log(data)
                this.$http.post(deleteSelectedSchoolGroup, data).then( response =>{
                  // console.log(response)
                  if(response.data.status == 200){
                    this.selected = []
                    this.$refs.filter.search();
                    this.getData()
                    this.makeToast('success', this.$t('Success'), this.$t('forms.delete_all.success'));

                  } else {
                    this.makeToast('danger', this.$t('Error'), this.$t('forms.delete_all.error'));
                  }

                }).catch(error => {
                  // console.log("Err -> ", error)
                })
              }
            })
        
        }
        
    },
    deleteModel(id){
      this.$bvModal
        .msgBoxConfirm(this.$t('forms.delete_group_confirmation'), {
          title: this.$t('forms.delete'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let data = {
                'id': id
              }
              this.$http.post(deleteSchoolGroup, data).then( response =>{
                // console.log(response)
                if(response.data.status == 200){
                  this.$refs.filter.search();
                  this.getData()
                  this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

                } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
                }

              }).catch(error => {
                // console.log("Err -> ", error)
              })
            }
          })
    },
    setloadingData(state){
        this.loading_data = state
    },
    setData(data){
      // console.log("set data", data)
        if(data.groups.length > 40000){
            this.groups = []
            this.fetch_message = $t('table.too_many_records')
            this.loading_data = false
            //this.groups.length = 0
        } else {
            this.fetch_message = ''
            this.groups = data.groups;
            this.loading_data = false
            //this.groups.length = data.groups_length
        }
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  },
}
</script>

<style>

</style>